import {mapGetters} from "vuex";
import {MutationGlobal} from "@/store/modules/GlobalModule/mutation";

const mixinStoreGetterGlobal = {
  computed: {
    ...mapGetters({
      isDesktop: `${[MutationGlobal.PATH_MODULE]}/${[MutationGlobal.isDesktop]}`,
      isMobile: `${[MutationGlobal.PATH_MODULE]}/${[MutationGlobal.isMobile]}`
    })
  }
}

export {
  mixinStoreGetterGlobal
}
