<template>
  <div class="widget-options">
    <a-select v-model="platformIdSelected">
      <a-select-option
        v-for="item in PLATFORM_OPTIONS"
        :key="item.name"
        :value="item.name"
        :disabled="!avalablePlatforms[item.uniqueName]"
      >
        {{ item.name }}
      </a-select-option>
    </a-select>
    <a-select v-model="durationDay">
      <a-select-option :value="7">Trong 7 ngày</a-select-option>
      <a-select-option :value="30">Trong 30 ngày</a-select-option>
      <a-select-option :value="90" disabled>
        Trong 90 ngày <span style="color: #ff7227">*</span>
      </a-select-option>
      <a-select-option :value="180" disabled>
        Trong 180 ngày <span style="color: #ff7227">*</span>
      </a-select-option>
    </a-select>
    <a-select v-model="chartType">
      <a-select-option :value="1">Ngành hàng</a-select-option>
      <a-select-option :value="2">Thương hiệu</a-select-option>
      <a-select-option :value="3">Shop hàng đầu</a-select-option>
    </a-select>
    <a-button class="analyst-button" @click="onSubmit">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mr-1"
      >
        <path
          d="M4.83397 0.167969C4.0913 0.168075 3.35939 0.345429 2.69905 0.685292C2.03871 1.02515 1.46903 1.51771 1.03733 2.12202C0.605642 2.72633 0.324413 3.42495 0.217019 4.15981C0.109626 4.89467 0.179169 5.64455 0.419871 6.34712C0.660572 7.0497 1.06548 7.68469 1.60094 8.1993C2.1364 8.71392 2.78695 9.09331 3.49853 9.30594C4.2101 9.51858 4.96215 9.55831 5.69217 9.42184C6.42219 9.28537 7.1091 8.97664 7.6958 8.5213L10.8376 11.6643C10.9472 11.7737 11.0957 11.8351 11.2505 11.835C11.4053 11.8349 11.5537 11.7733 11.6631 11.6637C11.7724 11.5542 11.8338 11.4057 11.8337 11.2509C11.8336 11.0961 11.772 10.9477 11.6625 10.8383L8.52063 7.69647C9.05619 7.00665 9.38744 6.1804 9.47671 5.31167C9.56598 4.44293 9.40969 3.56658 9.02561 2.78226C8.64154 1.99795 8.04509 1.33714 7.30409 0.874983C6.56309 0.412828 5.70728 0.167872 4.83397 0.167969V0.167969ZM1.33397 4.83464C1.33397 3.90638 1.70272 3.01614 2.35909 2.35976C3.01547 1.70338 3.90571 1.33464 4.83397 1.33464C5.76223 1.33464 6.65246 1.70338 7.30884 2.35976C7.96522 3.01614 8.33397 3.90638 8.33397 4.83464C8.33397 5.76289 7.96522 6.65313 7.30884 7.30951C6.65246 7.96589 5.76223 8.33464 4.83397 8.33464C3.90571 8.33464 3.01547 7.96589 2.35909 7.30951C1.70272 6.65313 1.33397 5.76289 1.33397 4.83464V4.83464Z"
          fill="currentColor"
        />
      </svg>
      Phân tích
    </a-button>
  </div>
</template>

<script>
import {
  ALL_COUNTRY_SUPPORTED,
  ALL_PLATFORM_BASE_OBJECT,
} from "@/constant/general/GeneralConstant";
import PieChartWithInnerText from "@/components/chart/PieChartWithInnerText";
import BarChart from "@/components/chart/BarChart";
import { getBunchOfAvailablePlatformObjectByPermissionName } from "@/service/platform/PlatformCommonService";
import { PERMISSION } from "@/constant/general/PermissionConstant";

export default {
  name: "Filter",
  components: {
    PieChartWithInnerText,
    BarChart,
  },
  data() {
    return {
      platformIdSelected: "Shopee",
      durationDay: 30,
      chartType: 1,
      PLATFORM_OPTIONS: Object.values(ALL_PLATFORM_BASE_OBJECT).filter(
        (item) => item.country === ALL_COUNTRY_SUPPORTED.vietnam
      ),
    };
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$emit("submit", {
        platformIdSelected: this.platformIdSelected,
        durationDay: this.durationDay,
        chartType: this.chartType,
      });
    },
  },
  computed: {
    avalablePlatforms() {
      return getBunchOfAvailablePlatformObjectByPermissionName(
        PERMISSION.market_default.name
      );
    },
  },
};
</script>


<style lang="scss" scoped>
.widget-options {
  padding-top: 12px;
  margin-bottom: 24px;
  .ant-select {
    margin-right: 12px;
    width: 140px;
  }
}
.analyst-button {
  border-color: #ff7227;
  color: #ff7227;
  &:hover {
    border-color: #ff7227;
    color: #fff;
    background: #ff7227;
  }
}
</style>
