<template>
  <div>
    <div class="chart-header mb-4">
      <h3 class="chart-name">Thống kê Ngành hàng</h3>
      <a-radio-group v-model="radio" name="radioGroup">
        <a-radio value="1">Doanh số 30 ngày</a-radio>
        <a-radio value="2">Sản phẩm đã bán 30 ngày</a-radio>
      </a-radio-group>
    </div>
    <div class="chart mb-6">
      <BarChart
        class=""
        :comparison-color="['#6B6B6B', '#8E8E8E', '#A7A7A7', '#E2E2E2']"
        :title="'title'"
        :sub-title="'Doanh số'"
        :categories-name="categoriesName"
        :category-title="'Ngành hàng'"
        :series-data="seriesData"
        :series-data-multiple-platform-by-categories="null"
        :series-title="'Doanh số'"
        :chart-height="300"
      />
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/chart/BarChart";

export default {
  components: {
    BarChart,
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      radio: "1",
    };
  },
  computed: {
    categoriesName() {
      return this.radio === "1"
        ? this.chartData.categoriesName
        : [...this.chartData.categoriesName]?.reverse();
    },
    seriesData() {
      return this.radio === "1"
        ? this.chartData.seriesData
        : [...this.chartData.seriesData]?.reverse();
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-header {
  text-align: center;
  .chart-name {
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    color: #3b3b3b;
  }
}
.chart {
  padding: 0 75px;
}
</style>
